<template>
  <div>
    <v-toolbar color="comment" rounded="lg" dense class="mb-4">
      <v-spacer></v-spacer>
      <v-btn small class="btnclean mr-1" @click.prevent="cleanData">
        <v-icon>mdi-broom</v-icon>
      </v-btn>
      <v-btn
        v-if="$store.getters['rolesUser/getCustomRole'](51)"
        small
        class="mr-1 btnsave"
        @click.prevent.stop="addNewTransfer"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row dense>
      <v-col cols="12" md="3">
        <date-picker
          label="Fecha: "
          @dateSelected="(param) => (date = param)"
          v-model="date"
          :value="date"
        ></date-picker>
      </v-col>
      <v-col cols="12" md="3">
        <v-select outlined dense label="Almacén (Origen): "></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select outlined dense label="Almacén (Destino): "></v-select>
      </v-col>
      <v-col cols="12" md="3" align="center">
        <v-btn @click.prevent="showPrices = !showPrices">
          <v-icon>mdi-plus</v-icon>&nbsp;&nbsp;Agregar producto
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          block
          dark
          large
          color="comment"
          class="rounded-lg"
          @click.prevent="showNotes = !showNotes"
        >
          <v-icon>mdi-comment</v-icon>
          <span class="text-h6">Comentarios</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="my-2">
        <div class="text-subtitle-1 font-weight-medium">
          <b>Indicación:</b> Editar celda (Unidades a transferir) para
          especificar la cantidad a transferir al almacén destino
        </div>
      </v-col>
    </v-row>
    <GeneralFilter
      entity="producto"
      :filterEndpoint="filterTransferProduct"
      :search="search"
      @emptyFilter="getProductsToShow"
      @filtered="filterHandler"
    >
      <DxDataGrid>
        <DxExport :enabled="true" />
        <DxEditing
          :allow-updating="true"
          :select-text-on-edit-start="true"
          start-edit-action="click"
          mode="cell"
        />
        <DxColumn cell-template="show-template" caption=""></DxColumn>
        <template :v-model="show - template">
          <a class="mx-1">
            <v-icon color="primary">mdi-eye</v-icon>
          </a>
        </template>
        <DxColumn caption="Nombre de Producto"></DxColumn>
        <DxColumn caption="Almacén (Origen)"></DxColumn>
        <DxColumn
          data-field="Amount"
          cell-header-template="headerNameAmount"
          data-type="number"
        >
          <DxPatternRule
            :pattern="itemQtyPattern"
            message="La cantidad del producto solo puede contener numeros enteros mayores a 0"
          />
          <DxAsyncRule
            :validation-callback="handleStockAndQty"
            message="La cantidad del producto no puede exceder el stock actual en el almacen"
          />
        </DxColumn>
        <template #headerNameAmount="{}"
          ><div>Unidades a<br />transferir</div></template
        >
        <DxColumn
          :width="columnWidth"
          cell-template="stock-template"
          caption="Nueva existencia"
        ></DxColumn>
        <template #stock-template="{ data }">
          {{ calculateCellValue(data.data) }}
        </template>
        <DxColumn
          :width="columnWidth"
          cell-template="lote-template"
          caption="Lote"
        ></DxColumn>
        <template #lote-template="{ data }">
          {{ data.data.ID_ItemBatch ? data.data.ID_ItemBatch : "Por defecto" }}
        </template>

        <DxColumn
          :width="columnWidth"
          :allow-editing="false"
          data-field="warehouseAtDestination"
          caption="Almacén (Destino)"
        ></DxColumn>
        <DxColumn
          :width="columnWidth"
          :allow-editing="false"
          data-field="ItemCurrentQuantityAtDestination"
          caption="Existencia actual"
        ></DxColumn>
        <DxColumn
          :width="columnWidth"
          cell-template="stock-template2"
          caption="Nueva existencia"
        ></DxColumn>
        <template #stock-template2="{ data }">
          {{ calculateCellValue2(data.data) }}
        </template>
        <DxColumn
          :width="columnWidth"
          cell-template="lote-template2"
          caption="Lote"
        ></DxColumn>
        <template #lote-template2="{ data }">
          {{
            data.data.ID_ItemBatchAtDestination
              ? data.data.ID_ItemBatchAtDestination
              : "Por defecto"
          }}
        </template>

        <DxColumn :width="40" cell-template="show-template" caption="">
        </DxColumn>
        <template #show-template="{ data }">
          <a href="#" class="mx-1" @click.prevent.stop="removeRow(data)">
            <v-icon color="error">mdi-delete</v-icon>
          </a>
        </template>
      </DxDataGrid>
    </GeneralFilter>

    <v-dialog v-model="showPrices" width="80%" scrollable>
      <v-card>
        <v-card-title class="text-h5">
          Productos agregados {{ counter }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-btn v-if="showdeselect" @click="deselectAll" color="primary"
                >Deseleccionar todas</v-btn
              >
              <v-btn v-else @click="selectAll" color="primary"
                >Seleccionar todas</v-btn
              >
              &nbsp;&nbsp;<v-btn @click.prevent="addProducts" color="save" dark
                >Agregar</v-btn
              >
            </v-col>
            <VersatileFilter
              :columnsSearch="columsConfig"
              :disableDates="true"
              ref="generalFilter"
              :search="{}"
              :columnfilterEndpoint="
                $API.products.getProductInventoryHistoryByColumns
              "
              @emptyFilter="clearInventoryFilter"
              @filtered="handleInventoryFilter"
            >
              <DxDataGrid
                class=""
                :data-source="inventory"
                key-expr="ID_Item"
                :show-row-lines="showRowLines"
                @exporting="onExporting"
                :show-borders="showBorders"
                :row-alternation-enabled="rowAlternationEnabled"
                :scrolling="{ useNative: true }"
                :column-auto-width="true"
              >
                <DxExport :enabled="true" />

                <DxColumn cell-template="show-template" caption=""></DxColumn>
                <DxPaging :page-size="5" />
                <DxColumn
                  :width="columnWidth"
                  data-field="ID_Item"
                  caption="ID de Producto/Servicio"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  data-field="PrincipalBarCode"
                  caption="Código Alterno Principal"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  data-field="WarehouseName"
                  caption="Almacen"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  cell-template="stock-template"
                  caption="Existencia"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  cell-template="lote-template"
                  caption="lote"
                ></DxColumn>
                <DxColumn
                  data-field="ItemName"
                  caption="Nombre de Producto/Servicio"
                ></DxColumn>
                <template #show-template="{ data }">
                  <v-checkbox
                    v-model="data.data.checkbox"
                    class="pt-0"
                    @click="showProduct(data)"
                  >
                  </v-checkbox>
                </template>
                <template #stock-template="{ data }">
                  {{ data.data.ItemCurrentQuantity }}
                  {{ data.data.ItemMeasureUnit }}
                </template>
                <template #lote-template="{ data }">
                  {{
                    data.data.ID_ItemBatch
                      ? data.data.ID_ItemBatch
                      : "Por defecto"
                  }}
                </template>
              </DxDataGrid>
            </VersatileFilter>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="1024" v-model="showNotes">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn small dark color="error" @click.prevent="showNotes = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <quill-editor
            :content="form.notes"
            :options="quillOptions"
            @change="onEditorChange($event)"
          ></quill-editor>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import GeneralFilter from "@/components/GeneralFilter.vue";
import VersatileFilter from "@/components/VersatileFilter";
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxEditing,
  DxPatternRule,
  DxAsyncRule,
} from "devextreme-vue/data-grid";

export default {
  name: "CreateTransfer",
  components: {
    DatePicker,
    GeneralFilter,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxEditing,
    DxPatternRule,
    DxAsyncRule,
    VersatileFilter,
  },
  data() {
    return {
      date: null,
      counter: 0,
      showNotes: false,
      showPrices: false,
      itemQtyPattern: /^(0|[1-9]\d*)$/,
      form: {},
      search: {
        filter: null,
      },
      filter: {
        family: null,
        category: null,
        subcategory: null,
        providers: null,
      },
      columsConfig: [
        {
          name: "PR.family",
          title: "Familia",
          value: "",
          type: "select",
          options: [],
        },
        {
          name: "PR.category",
          title: "Categoría",
          value: "",
          parent: "PR.family",
          type: "select",
          options: [],
        },
        {
          name: "PR.subcategory",
          title: "Subcategoría",
          value: "",
          parent: "PR.category",
          type: "select",
          options: [],
        },
      ],
    };
  },
  mounted() {
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid;
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch;
    this.search.type = 0;
  },
  methods: {
    filterTransferProduct({ filter, products }) {
      let filteredProducts = products.filter(
        (product) =>
          product.ID_Item.toString().includes(filter) ||
          product.ItemName.includes(filter) ||
          product.Amount.toString().includes(filter) ||
          product.ItemCurrentQuantity.toString().includes(filter)
      );

      return new Promise((resolve) => {
        resolve(filteredProducts);
      });
    },

    showProduct() {
      this.counter = 0;
      this.productsToBill.forEach((element) => {
        if (element.checkbox) {
          this.counter++;
        }
      });
    },

    deselectAll() {
      this.productsToBill.forEach((element) => {
        element.checkbox = false;
      });
      this.showdeselect = false;
      this.counter = 0;
    },

    selectAll() {
      this.productsToBill.forEach((element) => {
        element.checkbox = true;
      });
      this.counter = this.productsToBill.length;
      this.showdeselect = true;
    },

    onEditorChange(e) {
      this.form.comments = e.html;
    },

    handleInventoryFilter(response) {
      if (response.length === 0 && this.didHaveAllData === false) {
        this.didHaveAllData = true;
        this.getInventoryListAll();
        this.$refs.generalFilter.filterHandler();
      }
      this.inventory = response.filter(
        (product) => product.Warehouse == this.form.warehouseOrigin
      );
    },
  },
};
</script>
