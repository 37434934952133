<template>
  <div>
    <block v-if="loading"></block>
    <v-toolbar color="comment" rounded="lg" dense class="mb-4">
      <v-spacer></v-spacer>
      <v-btn small class="btnclean mr-1" @click.prevent="cleanData">
        <v-icon>mdi-broom</v-icon>
      </v-btn>
      <v-btn
        v-if="$store.getters['rolesUser/getCustomRole'](51)"
        small
        class="mr-1 btnsave"
        @click.prevent.stop="addNewTransfer"
      >
        <v-icon>mdi-content-save</v-icon>Realizar Traspaso
      </v-btn>
    </v-toolbar>
    <v-row dense>
      <v-col cols="12" md="3">
        <date-picker
          label="Fecha: "
          @dateSelected="(param) => (date = param)"
          v-model="date"
          :value="date"
        ></date-picker>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          attach
          :items="filteredWarehousesForOrigin"
          label="Almacén (Origen): "
          @change="getItemsFromWarehouseOrigin()"
          v-model="form.warehouseOrigin"
          item-text="Warehouse"
          item-value="ID_Warehouse"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          attach
          :items="filteredWarehousesForDestination"
          label="Almacén (Destino): "
          v-model="form.warehouseDestination"
          item-text="Warehouse"
          item-value="ID_Warehouse"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" align="center">
        <v-btn
          :disabled="!form.warehouseOrigin || !form.warehouseDestination"
          color="primary"
          @click.prevent="showPrices = !showPrices"
        >
          <v-icon>mdi-plus</v-icon>&nbsp;&nbsp;Agregar producto
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          block
          dark
          large
          color="comment"
          class="rounded-lg"
          @click.prevent="showNotes = !showNotes"
        >
          <v-icon>mdi-comment</v-icon>
          <span class="text-h6">Comentarios</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="my-2">
        <div class="text-subtitle-1 font-weight-medium">
          <b>Indicación:</b> Editar celda (Unidades a transferir) para
          especificar la cantidad a transferir al almacén destino
        </div>
      </v-col>
    </v-row>
    <GeneralFilter
      entity="producto"
      :filterEndpoint="filterTransferProduct"
      :search="search"
      @emptyFilter="getProductsToShow"
      @filtered="filterHandler"
    >
      <DxDataGrid :data-source="productsToTransfer">
        <DxExport :enabled="true" />
        <DxEditing
          :allow-updating="true"
          :select-text-on-edit-start="true"
          start-edit-action="click"
          mode="cell"
        />
        <DxColumn cell-template="show-template" caption=""></DxColumn>
        <template #show-template="{ data }">
          <v-btn
            icon
            small
            @click.prevent.stop="productsTransferShow(data.data.composedOf)"
          >
            <v-icon color="primary">mdi-eye</v-icon>
          </v-btn>
        </template>
        <DxColumn
          data-field="ItemComercialName"
          caption="Nombre de Producto"
        ></DxColumn>
        <DxColumn
          data-field="Quantity"
          header-cell-template="headerNameAmount"
          data-type="number"
        >
        </DxColumn>
        <template #headerNameAmount="{}"
          ><div>Unidades a<br />transferir</div></template
        >
        <DxColumn
          :width="columnWidth"
          :allow-editing="false"
          data-field="Warehouse"
          caption="Almacén (Destino)"
        ></DxColumn>
        <DxColumn
          :width="columnWidth"
          :allow-editing="false"
          data-field="ItemCurrentQuantity"
          caption="Existencia actual"
        ></DxColumn>
        <DxColumn
          :width="columnWidth"
          cell-template="stock-template2"
          caption="Nueva existencia"
        ></DxColumn>
        <template #stock-template2="{ data }">
          {{ calculateExistenciaDestino(data.data) }}
        </template>
        <DxColumn
          :width="columnWidth"
          cell-template="lote-template2"
          data-field="ItemLote"
          caption="Lote"
        ></DxColumn>
        <template #lote-template2="{ data }">
          {{
            data.data.ID_ItemBatchAtDestination
              ? data.data.ID_ItemBatchAtDestination
              : "Por defecto"
          }}
        </template>

        <DxColumn :width="40" cell-template="show-template3" caption="">
        </DxColumn>
        <template #show-template3="{ data }">
          <a href="#" class="mx-1" @click.prevent.stop="removeRow(data)">
            <v-icon color="error">mdi-delete</v-icon>
          </a>
        </template>
      </DxDataGrid>
    </GeneralFilter>

    <v-dialog v-model="showPrices" width="80%" scrollable>
      <v-card>
        <v-card-title class="text-h5">
          Productos agregados {{ counter }}
          <v-spacer />
          <v-btn small dark color="error" @click.prevent="showPrices = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-btn @click="selectAll(!isSelectedtAll)" color="primary">{{
                isSelectedtAll ? "Deseleccionar todas" : "Seleccionar todas"
              }}</v-btn>
              <v-btn class="ml-2" @click.prevent="addProducts" color="save" dark
                >Agregar</v-btn
              >
            </v-col>
            <VersatileFilter
              :columnsSearch="columsConfig"
              :disableDates="true"
              ref="generalFilter"
              :search="{}"
              :columnfilterEndpoint="
                $API.products.getProductInventoryHistoryByColumns
              "
              @emptyFilter="clearInventoryFilter"
              @filtered="handleInventoryFilter"
            >
              <DxDataGrid
                :data-source="products"
                key-expr="ID_Item"
                :show-row-lines="showRowLines"
                @exporting="onExporting"
                :show-borders="showBorders"
                :row-alternation-enabled="rowAlternationEnabled"
                :scrolling="{ useNative: true }"
                :column-auto-width="true"
              >
                <DxExport :enabled="true" />

                <DxColumn cell-template="show-template" caption=""></DxColumn>
                <DxPaging :page-size="5" />
                <DxColumn
                  :width="columnWidth"
                  data-field="ID_Item"
                  caption="ID de Producto/Servicio"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  data-field="PrincipalBarCode"
                  caption="Código Alterno Principal"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  data-field="WarehouseName"
                  caption="Almacen"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  cell-template="stock-template"
                  caption="Existencia"
                ></DxColumn>
                <DxColumn
                  :width="columnWidth"
                  cell-template="lote-template"
                  caption="lote"
                ></DxColumn>
                <DxColumn
                  data-field="ItemName"
                  caption="Nombre de Producto/Servicio"
                ></DxColumn>
                <template #show-template="{ data }">
                  <v-checkbox v-model="data.data.checkbox" class="pt-0">
                  </v-checkbox>
                </template>
                <template #stock-template="{ data }">
                  {{ data.data.ItemCurrentQuantity }}
                  {{ data.data.ItemMeasureUnit }}
                </template>
                <template #lote-template="{ data }">
                  {{
                    data.data.ID_ItemBatch
                      ? data.data.ID_ItemBatch
                      : "Por defecto"
                  }}
                </template>
              </DxDataGrid>
            </VersatileFilter>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="1024" v-model="showNotes">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn small dark color="error" @click.prevent="showNotes = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <quill-editor
            :content="form.notes"
            :options="quillOptions"
            @change="onEditorChange($event)"
          ></quill-editor>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailProductTransfer">
      <v-card>
        <v-card-title>
          Detalle de Producto
          <v-spacer />
          <v-btn
            small
            dark
            color="error"
            @click.prevent="detailProductTransfer = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DxDataGrid
            style="width: 100%"
            ref="dxDataGridTransfer"
            :scrolling="{ useNative: true }"
            :data-source="detailproduct"
            key-expr="ID_Item"
            @exporting="onExporting"
          >
            <DxExport :enabled="true" />
            <DxEditing
              :allow-updating="true"
              :select-text-on-edit-start="true"
              start-edit-action="click"
              mode="cell"
            />
            <DxColumn
              :width="columnWidth"
              :allow-editing="false"
              data-field="ID_Item"
              caption="ID de Insumo"
            ></DxColumn>
            <DxColumn
              :width="columnWidth"
              :allow-editing="false"
              data-field="ItemComercialName"
              caption="Nombre del Insumo"
            ></DxColumn>
            <DxColumn
              :width="columnWidth"
              :allow-editing="false"
              data-field="Warehouse"
              caption="Almacen de Origen"
            ></DxColumn>
            <DxColumn
              :width="columnWidth"
              :allow-editing="false"
              data-field="ItemCurrentQuantity"
              caption="Existencia Actual"
            ></DxColumn>
            <DxColumn
              data-field="Quantity"
              caption="Unidad a transferir"
              cell-header-template="headerNameAmount"
              data-type="number"
            >
            </DxColumn>
            <template #headerNameAmount="{}"
              ><div>Unidades a<br />transferir</div></template
            >
            <DxColumn
              :width="columnWidth"
              :allow-editing="false"
              cell-template="stock-origin"
              caption="Nueva Existencia"
            ></DxColumn>
            <template #stock-origin="{ data }">
              {{ calculateCellValue(data.data) }}
            </template>
            <DxColumn
              :width="columnWidth"
              :allow-editing="false"
              data-field="ID_User_Insert"
              caption="Usuario que Realiza"
            ></DxColumn>
            <DxColumn
              :width="columnWidth"
              :allow-editing="false"
              data-field="ID_User_Upddate"
              caption="Usuario que Actualiza"
            ></DxColumn>
          </DxDataGrid>
        </v-card-text>
      </v-card>
    </v-dialog>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import GeneralFilter from "@/components/GeneralFilter.vue";
import VersatileFilter from "@/components/VersatileFilter";
import Block from '@/components/Block.vue';
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxEditing,
} from "devextreme-vue/data-grid";
import Alerts from '@/components/Alerts.vue';

export default {
  name: "CreateTransfer",
  components: {
    DatePicker,
    GeneralFilter,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxEditing,
    VersatileFilter,
    Alerts,
    Block
  },
  data() {
    return {
      date: null,
      counter: 0,
      showNotes: false,
      showPrices: false,
      detailProductTransfer: false,
      productTransferShow: [],
      products: [],
      productsToTransfer: [],
      detailproduct: [],
      warehouses: [],
      filterWarehouses: {},
      transferRequestActive: false,
      requestStatus: null,
      requestId: null,
      form: {
        warehouseOrigin: "",
        warehouseDestination: "",
        comments: "",
      },
      inventory: [],
      search: {
        filter: null,
      },
      filter: {
        family: null,
        category: null,
        subcategory: null,
        providers: null,
      },
      columsConfig: [
        {
          name: "PR.family",
          title: "Familia",
          value: "",
          type: "select",
          options: [],
        },
        {
          name: "PR.category",
          title: "Categoría",
          value: "",
          parent: "PR.family",
          type: "select",
          options: [],
        },
        {
          name: "PR.subcategory",
          title: "Subcategoría",
          value: "",
          parent: "PR.category",
          type: "select",
          options: [],
        },
      ],
      loading: false,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
    };
  },
  computed: {
    productsSelected() {
      return this.products.filter((item) => item.checkbox);
    },
    isSelectedtAll() {
      return this.productsSelected.length === this.products.length;
    },
    columnWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "160px" : "auto !important";
    },
    filteredCategories() {
      return this.filter.family
        ? this.categories.filter(
            (category) => category.id_family == this.filter.family
          )
        : [];
    },
    filteredSubcategories() {
      return this.filter.category
        ? this.subcategories.filter(
            (subcategory) => subcategory.id_cat == this.filter.category
          )
        : [];
    },
    filteredWarehousesForOrigin() {
      return this.warehouses.filter(
        (warehouse) => warehouse.ID_Warehouse != this.form.warehouseDestination
      );
    },
    filteredWarehousesForDestination() {
      return this.warehouses.filter(
        (warehouse) => warehouse.ID_Warehouse != this.form.warehouseOrigin
      );
    },
  },
  mounted() {
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid;
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch;
    this.search.type = 0;
    this.getWarehouse();
  },
  methods: {
    async addNewTransfer() {
      this.loading = true
      try {
        const body = {
          ID_WarehouseOrigin: this.form.warehouseOrigin,
          ID_WarehouseDestination: this.form.warehouseDestination,
          isRequest: 1,
          comments: this.form.comments,
          Products: this.productsToTransfer,
        };

        const result = await this.$API.inventoryTransfer.addTransferProductComposite(body);
        
        this.loading = false
        if (result?.success) {
          this.showAlert(
            "success", 
            "Exito",
            result.message
          )
        }
        else {
          this.showAlert(
            "danger",
            "Error",
            result?.message
          );
        }
      } catch (error) {
        this.loading = false
        this.showAlert(
          "danger",
          "Error",
          "Error al guardar el traspaso " + error.message
        );
      }
    },
    cleanData() {},
    productsTransferShow(data) {
      this.detailproduct = data;
      this.detailProductTransfer = true;
    },
    calculateExistenciaDestino(data) {
      data.StockResultAtDestination = data.ItemCurrentQuantity + data.Quantity;
      const stockDestino = data.StockResultAtDestination;
      return stockDestino;
    },
    calculateCellValue(data) {
      data.StockResultOrigin = data.ItemCurrentQuantity - data.Quantity;
      const stockOrigen = data.StockResultOrigin;
      return stockOrigen;
    },
    addProducts() {
      const productsToAdd = JSON.parse(JSON.stringify(this.productsSelected));
      this.productsToTransfer = productsToAdd;
      this.selectAll(false);
      console.log(JSON.parse(JSON.stringify(this.productsToTransfer)));
    },
    filterTransferProduct({ filter, products }) {
      let filteredProducts = products.filter(
        (product) =>
          product.ID_Item.toString().includes(filter) ||
          product.ItemName.includes(filter) ||
          product.Quantity.toString().includes(filter) ||
          product.ItemCurrentQuantity.toString().includes(filter)
      );

      return new Promise((resolve) => {
        resolve(filteredProducts);
      });
    },

    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    closeAlert() {
      this.alert.show = false
    },
    getWarehouse() {
      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.filterWarehouses.branch = idbranch;
      this.filterWarehouses.business = idbusiness;
      this.$API.warehouse
        .getWarehouses(this.filterWarehouses)
        .then((response) => {
          this.warehouses = response;
        });
    },

    getItemsFromWarehouseOrigin() {
      this.inventory = this.productsToShowWarehouse.filter(
        (product) => product.Warehouse == this.form.warehouseOrigin
      );
    },

    selectAll(check) {
      this.products.forEach((element) => {
        element.checkbox = check;
      });
      this.counter = this.products.length;
    },

    onEditorChange(e) {
      this.form.comments = e.html;
    },

    filterHandler(response) {
      this.productsToShow = response;
    },

    handleInventoryFilter(response) {
      if (response.length === 0 && this.didHaveAllData === false) {
        this.didHaveAllData = true;
        this.getInventoryListAll();
        this.$refs.generalFilter.filterHandler();
      }
      this.inventory = response.filter(
        (product) => product.Warehouse == this.form.warehouseOrigin
      );
    },
  },
  watch: {
    async showPrices(value) {
      if (value) {
        const products =
          await this.$API.inventoryTransfer.getProductListComposite(
            this.form.warehouseOrigin,
            this.form.warehouseDestination,
            ""
          );
        this.products = products.map((item) => {
          item.composedOf = item.composedOf.map((item2) => {
            item2.Quantity = 0;
            return item2;
          });
          item.Quantity = 0;
          return item;
        });
      }
    },
  },
};
</script>

<style scoped></style>
